<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="trigger != null"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:text-left w-full">
                <h3
                  class="text-lg mb-2 leading-6 font-medium text-center w-full text-gray-900"
                  id="modal-title"
                >
                  Bloquear Reserva
                </h3>
                <div
                  v-if="error"
                  class="w-full bg-red-200 border rounded-md border-red-300 px-4 py-2 text-red-900 mt-1 mb-2 text-sm"
                >
                  {{ error }}
                </div>
                <!-- <div class="mt-2">
                  <p class="text-sm text-gray-500 mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Porro atque, voluptas, labore optio ratione fugiat amet,
                    architecto dolores praesentium nesciunt animi et. Nesciunt
                    nisi eos similique vel veniam delectus non!
                  </p>
                </div> -->
                <div class="w-full grid grid-cols-2 gap-2">
                  <div class="col-span-2">
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Selecciona al paciente
                    </label>

                    <div
                      class="w-full mb-4 flex flex-row justify-center items-center"
                    >
                      <div class="mt-1 flex rounded-md w-full">
                        <span
                          class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-200 bg-gray-50 text-gray-500 text-sm"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-gray-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span class="ml-2">Buscar</span>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          name="search-box"
                          id="company-website"
                          class="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-200"
                        />
                      </div>
                    </div>

                    <div
                      class="w-full border border-300 rounded-md overflow-y-scroll h-52 mb-4"
                    >
                      <div
                        v-for="(patient, patientIndex) in users_sort"
                        :key="patientIndex"
                        class="py-2 px-2 border-b border-dashed border-gray-300"
                      >
                        <div class="flex flex-row justify-between items-center">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 h-10 w-10">
                              <div
                                class="h-10 w-10 rounded-full bg-gray-200 flex justify-center items-center text-gray-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div
                              class="ml-4 flex flex-col justify-start items-center"
                            >
                              <div
                                class="text-sm w-full text-left font-medium text-gray-900"
                              >
                                {{
                                  capitalize(patient.names) +
                                    ' ' +
                                    capitalize(patient.lastnames)
                                }}
                              </div>
                              <div
                                class="text-sm w-full text-left text-gray-500"
                              >
                                {{ patient.email }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              @click="
                                () => {
                                  user = patient;
                                }
                              "
                              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                              Seleccionar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      v-if="user"
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Paciente Seleccionado
                    </label>
                    <div
                      v-if="user"
                      class="w-full border border-300 rounded-md mb-4"
                    >
                      <div class="py-2 px-2">
                        <div class="flex flex-row justify-between items-center">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 h-10 w-10">
                              <div
                                class="h-10 w-10 rounded-full bg-gray-200 flex justify-center items-center text-gray-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div
                              class="ml-4 flex flex-col justify-start items-center"
                            >
                              <div
                                class="text-sm w-full text-left font-medium text-gray-900"
                              >
                                {{
                                  capitalize(user.names) +
                                    ' ' +
                                    capitalize(user.lastnames)
                                }}
                              </div>
                              <div
                                class="text-sm w-full text-left text-gray-500"
                              >
                                {{ user.email }}
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
          >
            <button
              type="button"
              @click="save()"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              Bloquear
            </button>
            <button
              type="button"
              @click="toggle()"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BlockService from '@/services/blockService';
import moment from 'moment';
import PatientService from '@/services/patientService';

function initialState() {
  return {
    error: null,
    search: '',
    users: [],
    user: null,
  };
}

export default {
  name: 'BlockUser',
  props: {
    trigger: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return initialState();
  },
  created() {
    moment.locale('es');
    this.getUsers();
  },
  methods: {
    capitalize(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    save() {
      if (!this.user) {
        this.error = 'Debes seleccionar a un paciente';
        return;
      }
      BlockService.block({
        id: this.trigger,
        patient: this.user.id,
      })
        .then(() => {
          this.$emit('toggle', 'save');
          Object.assign(this.$data, initialState());
        })
        .catch(() => {
          this.error = 'error al bloquear!';
        });
    },
    toggle() {
      this.$emit('toggle', 'ok');
      Object.assign(this.$data, initialState());
    },
    getUsers() {
      PatientService.getPatients()
        .then(response => {
          this.users = response.data.patients;
        })
        .catch(() => {
          console.log('error getting users');
        });
    },
  },
  watch: {
    trigger() {
      this.getUsers();
    },
  },
  computed: {
    users_sort() {
      let temp = this.users;
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.names + ' ' + e.lastnames;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
