import authAxios from '@/utils/authAxios';

export default {
  getBlock: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + `/block/get-block/${id}`, {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getByDate: date => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block/get-by-date', {
          date,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getByDateAdmin: (id, date) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block/get-by-date-admin', {
          date,
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removePatientFromBlock: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .delete(
          process.env.VUE_APP_BACKEND_URL +
            `/block/remove-patient-from-block/${id}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  movePatientFromBlock: ({ from, to }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(
          process.env.VUE_APP_BACKEND_URL + '/block/move-patient-from-block',
          {
            from,
            to,
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  activateBlock: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block/block_activate', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  finishBlock: ({
    id,
    finish_type,
    extension,
    hospitalization,
    prescription,
  }) => {
    console.log(id);
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block/block_finish', {
          id,
          finish_type,
          extension,
          hospitalization,
          prescription,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   ********************************************************************************************************
   */

  getAll: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/block', {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  create: ({ start_date, finish_date, is_blocked, text, rate, full_rate }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block', {
          start_date,
          finish_date,
          is_blocked,
          text,
          rate,
          full_rate,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createAdmin: (
    id,
    { start_date, finish_date, is_blocked, text, rate, full_rate }
  ) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_admin', {
          id,
          start_date,
          finish_date,
          is_blocked,
          text,
          rate,
          full_rate,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  edit: ({ start_date, finish_date, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_edit', {
          start_date,
          finish_date,
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editAdmin: (doctor, { start_date, finish_date, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_edit_admin', {
          doctor,
          start_date,
          finish_date,
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createSchedule: ({ data, is_blocked, text, rate, full_rate }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockschedule', {
          data,
          is_blocked,
          text,
          rate,
          full_rate,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createScheduleAdmin: (id, { data, is_blocked, text, rate, full_rate }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockschedule_admin', {
          id,
          data,
          is_blocked,
          text,
          rate,
          full_rate,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteSchedule: data => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockschedule_delete', {
          data,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteScheduleAdmin: (id, data) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockschedule_admin_delete', {
          id,
          data,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .delete(process.env.VUE_APP_BACKEND_URL + '/block/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteAdmin: (doctor, id) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_delete_admin', {
          id: doctor,
          block: id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  final: ({ id, code, ges, results }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_note', {
          id,
          code,
          ges,
          results,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  block: ({ id, patient }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block/patient-assign', {
          id,
          patient,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  finalAdmin: (doctor, { id, code, ges, results }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_note_admin', {
          id,
          code,
          ges,
          results,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meeting: ({ id, url }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_meeting', {
          id,
          url,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meetingAdmin: (doctor, { id, url }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/block_meeting_admin', {
          id,
          url,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getNotes: patient => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/note/get-from-patient', {
          patient,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteMultiple: data => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockdeletemultiple', {
          data,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteMultipleAdmin: (id, data) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/blockdeletemultiple_admin', {
          id,
          data,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
